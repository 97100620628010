import {
  memoSumRems as sumLessVar,
  memoPx2Rem as px2rem,
  memoHiDPI as hiDPIpx,
  prettifyThemeVarNames
} from './cssHelpers'
import cmsPageUrl from './cmsPageUrl'
import dropdown from './dropdown'
import { formatCMSLabels, buildCmsModuleComponents } from './cms'
//@ts-ignore
import storage, {
  setUserAccessTokens as setUserAccessCookies,
  getAccessToken,
  getSiteLanguage,
  getRefreshToken,
  sessionAccessToken,
  prepareUAT
} from './storage'
import apiResponseValidator from './apiResponseValidator'
import updateUrlParams from './updateUrlParams'
import isExternalLink from './isExternalLink'
import ConvertWwwLinkToValidLink from './ConvertWwwLinkToValidLink'

import isDetailLink from './isDetailLink'
import stripHtml from './stripHtml'
import push from './push'
import { track as logger } from './logger'
import { getTranslationByName } from './getTranslationByName'
import isLegacyPath from './isLegacyPath'
import returnEmptyStringFromNullOrUndefined from './returnEmptyStringFromNullOrUndefined'
import isSpecialUrl from './isSpecialUrl'
import { formatDate, formatDateRange, setDayjsLocaleLanguage } from './date'
import noSSRurl from './noSSRurl'
import countdown from './countdown'
import { logPageView, logDeletedUser } from './analytics'
import updateCountdown from './updateCountdown'
import debounce from './debounce'
import {
  formatHour,
  formatEventLink,
  formatRoomLink,
  formatSpecialRoomLink,
  getHourAndMinutes,
  findMaxOpeningDate,
  calculateExpiryDate,
  findOpeningTime,
  getDatesExhibition,
  getLocationMapLink,
  formatDateDayPicker,
  parseDateDayPicker
} from './events'
import { formatGalleryLink, isGalleryStatus, showNameToSlug } from './galleries'
import { formatArtworkLink, formatOVRArtworkDetailLink } from './artworks'
import { formatArtistLink } from './artists'
import { formatArticleLink } from './articles'
import getProperty from './getProperty'
import mq from './styled-mq'
import jsMq from './js-mq'
import linkToTitle from './linkToTitle'
import findUrlContext from './findUrlContext'
import useIntersection from './useIntersection'
import ImageNext from './Image'
import { getAspectRatioPercent, detectDpr, detectAspectRatio, roundImageDimension } from './imageUtils'
import useSwipe from './useSwipe'
import { getCatalogFiltersRouteParams } from './catalog'
import shallowCompare from './shallowCompare'
import { getFieldsOfLabelEntityAsObject } from './labels'
import { mapResultGalleries } from './search'
import roundSearchResultNumber from './roundSearchResultNumber'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'
import { styleIf } from './styleCondition'
import StyleModalFullscreen from './StyleModalFullscreen'
import StyleModalBottom from './StyleModalBottom'
import formatArtworkTitle from './formatArtworkTitle'
import setQueryStringValue from './queryString'
import formatNumber from './formatNumber'
import getAgentKey from './getAgentKey'
import getFingerprint from './getFingerprint'
import getCookieExpireDate from './getCookieExpireDate'
import getRouterQueryParam from './getRouterQueryParam'
import isScrolledIntoView from './isScrolledIntoView'
import renameKey from './renameKey'
import { getImageByTag, getEventImage } from './getImages'
import { randomSeed } from './getRandomNumber'
import { stringReplacement } from './stringReplacement'
import isUserAllowedToEnter from './userAllowedToEnterRoom'
import isOkForHydration from './isOkForHydration'
import { detectBrowserLocaleByHostname, detectSiteLanguage, hostProtocol } from './localization'
import { scrollToElement } from './scroll'
import { removePasswordsFromResponse } from './removePasswordsFromResponse'
import redirectOnCtaUrl from './redirectOnCtaUrl'
import isUserAllowedToEnterArtworks from './userAllowedToEnterArtworks'
import { getLinkRel } from './getLinkRel'
import canonicalUrl from './canonicalUrl'
import getReferrer from './getReferrer'

const browserUtil = require('./browser')

const { isIE, isAndroidWebview, isiOSWebview } = browserUtil

export {
  getReferrer,
  canonicalUrl,
  getLinkRel,
  scrollToElement,
  hostProtocol,
  detectBrowserLocaleByHostname,
  detectSiteLanguage,
  formatDateRange,
  stringReplacement,
  getImageByTag,
  getEventImage,
  isScrolledIntoView,
  getRouterQueryParam,
  logPageView,
  prettifyThemeVarNames,
  findUrlContext,
  findOpeningTime,
  getHourAndMinutes,
  calculateExpiryDate,
  findMaxOpeningDate,
  sumLessVar,
  storage,
  cmsPageUrl,
  dropdown,
  formatCMSLabels,
  setUserAccessCookies,
  logger,
  px2rem,
  apiResponseValidator,
  updateUrlParams,
  isExternalLink,
  isIE,
  isAndroidWebview,
  isiOSWebview,
  isDetailLink,
  stripHtml,
  hiDPIpx,
  push,
  getTranslationByName,
  isLegacyPath,
  returnEmptyStringFromNullOrUndefined,
  isSpecialUrl,
  formatDate,
  getAccessToken,
  randomSeed,
  formatHour,
  formatEventLink,
  formatRoomLink,
  formatSpecialRoomLink,
  getSiteLanguage,
  buildCmsModuleComponents,
  formatGalleryLink,
  isGalleryStatus,
  showNameToSlug,
  mq,
  jsMq,
  linkToTitle,
  useIntersection,
  ImageNext,
  getDatesExhibition,
  getLocationMapLink,
  noSSRurl,
  detectDpr,
  detectAspectRatio,
  getAspectRatioPercent,
  useSwipe,
  getCatalogFiltersRouteParams,
  shallowCompare,
  getFieldsOfLabelEntityAsObject,
  formatArtworkLink,
  formatOVRArtworkDetailLink,
  formatArtistLink,
  formatArticleLink,
  mapResultGalleries,
  roundSearchResultNumber,
  getProperty,
  useIsomorphicLayoutEffect,
  formatDateDayPicker,
  parseDateDayPicker,
  countdown,
  styleIf,
  updateCountdown,
  debounce,
  roundImageDimension,
  formatArtworkTitle,
  StyleModalFullscreen,
  StyleModalBottom,
  setQueryStringValue,
  formatNumber,
  getAgentKey,
  getFingerprint,
  getCookieExpireDate,
  renameKey,
  isUserAllowedToEnter,
  getRefreshToken,
  sessionAccessToken,
  prepareUAT,
  isOkForHydration,
  setDayjsLocaleLanguage,
  removePasswordsFromResponse,
  redirectOnCtaUrl,
  logDeletedUser,
  isUserAllowedToEnterArtworks,
  ConvertWwwLinkToValidLink
}

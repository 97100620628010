import { FC } from 'react'
import { FormikConsumer } from 'formik'
import { Col, Button, Text } from '@mch-group/uikit-components'
import { useUserAccessContext } from '../context/useUserAccessContext'

const RedeemInvitationKeyStep: FC = () => {
  const {
    state: { apiErrorMessage, isLoading, labels: userAccessLabels, otherData },
    dispatch
  } = useUserAccessContext()
  return (
    <FormikConsumer >
      {({ handleBlur, values, errors, touched, handleChange }) => (
        <Col className={!otherData.isModal ? 'mt-0' : ''}>
          {!otherData.isModal && <h4 className='pb-10'>{userAccessLabels.Access.redeemInvitationHeadingTextLabel}</h4>}
          <p className='text-medium'>
            {userAccessLabels.Access.loginToRedeemInvitationKeySubtitle}
          </p>
          <Text
            label={userAccessLabels.Access.emailAddressLabel}
            type='email'
            value={values.email}
            onChange={(e) => {
                  handleChange(e)
                  dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
                }}
            onBlur={handleBlur}
            id='email'
            helpText={(touched.email && errors.email) ? errors.email as string : apiErrorMessage}
            helpTextType={(errors.email && touched.email) || apiErrorMessage ? 'danger' : 'muted'}
          />
          <div className='d-grid mt-10'>
            <Button
              type='submit'
              className='next-button'
              isLoading={isLoading}
            >
              {userAccessLabels.Access.continueLabel}
            </Button>
          </div>
        </Col>
      )}
    </FormikConsumer >
  )
}

export default RedeemInvitationKeyStep
import { FC } from 'react'
import { FormikConsumer } from 'formik'
import { Col, Button, Text } from '@mch-group/uikit-components'
import { useUserAccessContext } from '../context/useUserAccessContext'

interface IEnterVIPAccessCode {
  redeemKeyErrorMessage: String
}

const EnterVIPAccessCodeStep: FC<IEnterVIPAccessCode> = ({ redeemKeyErrorMessage }) => {
  const { state: {
    apiErrorMessage,
    isLoading,
    labels: userAccessLabels,
    otherData
  }, dispatch } = useUserAccessContext()
  const errorMessage = apiErrorMessage || redeemKeyErrorMessage
  return (
    <FormikConsumer >
      {({ handleBlur, values, errors, touched, handleChange }) => (
        <Col className={!otherData.isModal ? 'mt-0' : ''}>
          {!otherData.isModal && <h4 className='pb-10'>{userAccessLabels.Access.vipAccessCodeInfoLabel}</h4>}
          <p className='text-medium'>
            {userAccessLabels.Access.vipAccessCodeTitleLabel}
          </p>
          <Text
            label={userAccessLabels.Access.accessCodeHeading}
            type='text'
            value={values.vipcode?.replace(/\s+/g, '')}
            onChange={(e) => {
                  handleChange(e)
                  dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
                }}
            onBlur={handleBlur}
            id='vipcode'
            helpText={(touched.vipcode && errors.vipcode) ? errors.vipcode as string : errorMessage as string}
            helpTextType={(errors.vipcode && touched.vipcode) || apiErrorMessage || redeemKeyErrorMessage ? 'danger' : 'muted'}
          />
          <div className='d-grid mt-10'>
            <Button
              type='submit'
              className='next-button'
              isLoading={isLoading}
            >
              {userAccessLabels.Access.continueLabel}
            </Button>
          </div>
        </Col>
      )}
    </FormikConsumer>
  )
}

export default EnterVIPAccessCodeStep

// @ts-nocheck
import App from 'next/app'
import type { AppProps } from 'next/app'
import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { SWRConfig } from 'swr'
import PageProvider from '@providers/PageContext'
import SpecialRoomProvider from '@modules/SpecialRoom/SpecialRoomModule/SpecialRoomProvider'
import WrappedContexts from '@utils/WrappedContexts'
// import '@researchgate/react-intersection-observer'
import 'intersection-observer'

// design tokens
import '@mch-group/uikit-themes/dist/artbasel/design-tokens/css/design_tokens.css'

// Plain CSS imports
import '../styles/css/imports'

// css root variables
import '../public/static/styles/root.css'

// Some global styles for dashboard to appear grey background, etc.
import '../public/static/styles/dashboard.css'

// Some styles to allow Semantic UI and UIKit live together
import '../public/static/styles/adaptSemanticUIToUIKit.css'

// global sass
import '../styles/Global.scss'

// UIKIT vars to be converted to JS
import uikitVars from '../styles/variables.module.scss'

// SWR Default configuration for all swr requests
import swrContextConfig from '../services/swr/context.config.gb'

// System HoC's
import withReduxStore from '../hocs/withReduxStore'
import withUserAuth from '../hocs/withUserAuth'
import withCoreData from '../hocs/withCoreData'
import withErrorHandler from '../hocs/withErrorHandler'

import Layout from '../components/Layout'
import IAppProps from '../types/IAppProps'
import AppProvider from '@providers/AppContext'
import withQSActionHandler from '@hocs/withQSActionHandler'

/* This is similar to the "Window" object, no state, nothing, just a variable with a global scope. */
global.uikitVars = uikitVars

class MCHApp extends App {
  // Re-called on SSR and on every navigation
  render() {
    // Object created in app/src/pages/index.tsx
    const { props } = this
    const { Component, pageProps }: AppProps<IAppProps> = props
    // Destructuring reduxStore in a separate line to make the error more evident
    // @ts-ignore // Because reduxStore is not part of the NextJS props, it's a FE construction, and incorrect.
    const { reduxStore } = props
    return (
      <PageProvider state={{ ...pageProps }}>
        <AppProvider>
          <ThemeProvider theme={{
            name: getEnvConfig.fe.theme,
            var: MFP.vars
          }}>
            <Provider store={reduxStore}>
              <SWRConfig value={swrContextConfig}>
                <WrappedContexts>
                  <SpecialRoomProvider pageMetaInfo={pageProps?.pageData}>
                    <Layout {...props}>
                      {/* Here starts the render of modules in CMS, check CmsPage.tsx */}
                      <Component {...pageProps} />
                    </Layout>
                  </SpecialRoomProvider>
                </WrappedContexts>
              </SWRConfig>
            </Provider>
          </ThemeProvider>
        </AppProvider>
      </PageProvider>
    )
  }
}

export default
  withErrorHandler(
    withReduxStore(
      withUserAuth(
        withQSActionHandler(
          withCoreData(MCHApp)
        )
      )
    )
  )
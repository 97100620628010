import React, { FC, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import validationConstants from '@constants/validation'
import Router from 'next/router'

import useUserAccessData from './hooks/useUserAccessData'
import ModuleLayout from './ModuleLayout'
import RedeemInvitationInfo from './components/common/RedeemInvitationInfo'
import { useUserAccessContext } from './context/useUserAccessContext'

import {
  InitialStep,
  LoginWithOrWithoutPasswordStep,
  ResendLoginLinkStep,
  CreatePasswordStep,
  RegisterUserFirstStep,
  RegisterUserSecondStep,
  LinkExpiredStep,
  EnterVIPAccessCodeStep,
  RedeemInvitationKeyStep,
  ResendActivateAccountLink
} from './components'

import {
  LOGIN_WITH_OR_WITHOUT_PASSWORD,
  LOGIN_WITH_VIP_ACCESS_CODE,
  REGISTER_NEW_USER_FIRST_STEP,
  REDEEM_INVITATION_KEY_STEP,
  RESEND_LOGIN_LINK,
  REGISTER_NEW_USER_SECOND_STEP,
  CREATE_PASSWORD_STEP,
  LINK_EXPIRED_STEP,
  LOADING_SCREEN_STEP,
  RESEND_ACTIVATE_ACCOUNT_LINK_STEP,
  RESET_PASSWORD_STEP,
  RESET_PASSWORD_LINK_EXPIRED_STEP,
  RECOVER_ACCOUNT_FIRST_STEP,
  RECOVER_ACCOUNT_SECOND_STEP
} from './utils/AllSteps'
import ModalLoadingScreen from './components/common/ModalLoadingScreen'
import ResetPasswordStep from './components/ResetPasswordStep'
import ResetPasswordLinkExpired from './components/ResetPasswordLinkExpired'
import RecoverAccountFirstStep from './components/RecoverAccountFirstStep'
import RecoverAccountSecondStep from './components/RecoverAccountSecondStep'
import { Spinner } from '@mch-group/uikit-components'

interface UserAcceesFormProps {
  children: React.ReactNode,
  initialValues: object
}

const UserAcceesForm: FC<UserAcceesFormProps> = ({ children, initialValues }) => {
  const steps = React.Children.toArray(children)
  const [snapshot, setSnapshot] = useState(initialValues)
  const step = steps[0]

  const submitUserAccessForm = async (values, bag) => {
    //@ts-ignore
    if (step.props.onSubmit) {
      bag.setTouched({})
      //@ts-ignore
      await step.props.onSubmit(values)
    }
    else { setSnapshot(values) }
  }

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={submitUserAccessForm}
      //@ts-ignore
      validationSchema={step && step.props && step.props.validationSchema}
    >
      {({ handleSubmit }) => <form onSubmit={handleSubmit}>{step}</form>}
    </Formik>
  )
}

const UserAcceesFormSteps = ({ children }) => children

const UserAccessModule: FC = () => {
  const {
    router,
    verifyUserAlreadyRegistered,
    nextStep,
    handleVIPAccessCode,
    submitUserLoginDetail,
    sendMagicLinkForLogin,
    countries,
    handleUserRegistration,
    redeemKeyErrorMessage,
    redirectUserToSpecificUrl,
    createNewPassword,
    resetPassword,
    showLoadingScreen,
    handleBackArrowClick,
    userInfo,
    loginWithVipAccessCodeStep,
    redeemInvitationStep,
    activateAccountLabel,
    recoverAccountMoreInfoLabel,
    recoverDeletedAccount
  } = useUserAccessData()
  const { state: { labels, resendLoginLinkRef, resendResetPasswordLinkRef,
    loginAccessPageUrl, otherData } } = useUserAccessContext()

  // UIKIT Spinner is not working well, it relies on these DIVs around to show correctly
  if (!labels) return (
    <div className='d-flex align-items-center justify-content-center mb-5'>
      <Spinner variant='button' theme='light' />
    </div>)

  return (
    <UserAcceesForm
      initialValues={{
        email: router.query.email ? router.query.email : '',
        accessCode: router.query.code ? router.query.code : '',
        password: '',
        newPassword: '',
        newPasswordConfirm: '',
        country: '',
        vipcode: router.query.vipcode ? router.query.vipcode : '',
        firstName: '',
        lastName: '',
        termsCondition: false,
        newsletter: false,
        showPassword: false
      }}
    >
      {nextStep === LOADING_SCREEN_STEP &&
        <UserAcceesFormSteps>
          <ModalLoadingScreen />
        </UserAcceesFormSteps>
      }
      {!nextStep && !router?.query.code &&
        <UserAcceesFormSteps
          //@ts-ignore
          onSubmit={(values) => (
            values.submitBtn === 'type 1'
              ? verifyUserAlreadyRegistered(values)
              : loginWithVipAccessCodeStep()
          )}
          validationSchema={() => Yup.object().shape({
            email: Yup.string()
              .email(labels?.Access.invalidEmailErrorLabel)
              .matches(
                validationConstants.ROMAN_ACCENTED, labels?.Access.romamCharactersErrorLabel
              )
          })}
        >
          <ModuleLayout>
            <InitialStep />
          </ModuleLayout>
        </UserAcceesFormSteps>
      }
      {nextStep === LOGIN_WITH_OR_WITHOUT_PASSWORD &&
        <UserAcceesFormSteps
          //@ts-ignore
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .min(7, labels.Access.passwordValidationFailedLabel)
          })}
        >
          <ModuleLayout
            backLabel={labels.Access.backButtonLabel}
            isDisplayBackButton
            //@ts-ignore
            onClickBackBtn={() => { handleBackArrowClick(null) }}
          >
            <LoginWithOrWithoutPasswordStep
              //@ts-ignore
              userFirstName={userInfo?.firstName}
              sendMagicLinkForLogin={sendMagicLinkForLogin}
              submitUserLoginDetail={submitUserLoginDetail}
            />
          </ModuleLayout>
        </UserAcceesFormSteps>
      }
      {nextStep === RECOVER_ACCOUNT_FIRST_STEP &&
        //@ts-ignore
        <UserAcceesFormSteps
          //@ts-ignore
          onSubmit={(values) => {
            if (values.submitBtn === 'type 1') {
              const params = {
                email: userInfo?.email,
                useId: userInfo?.id
              }
              recoverDeletedAccount(params)
            } else {
              if (otherData.isModal) {
                handleBackArrowClick(null, labels.Access.logInLabel, '', () => { })
              } else {
                handleBackArrowClick(null, '', '', () => { })
              }
            }
          }}
        >
          <ModuleLayout
            isDisplayBackButton={false}
          >
            <RecoverAccountFirstStep
              //@ts-ignore
              userFirstName={userInfo?.firstName}
              sendMagicLinkForLogin={sendMagicLinkForLogin}
              submitUserLoginDetail={submitUserLoginDetail}
              //@ts-ignore
              onClickBackBtn={() => { handleBackArrowClick(null) }}
              onClickCloseBtn={() => {
                if (otherData.isModal) {
                  typeof otherData.onCloseLoginModal === 'function' && otherData.onCloseLoginModal()
                  return
                }
                router.push('/')
              }}
              firstName={userInfo?.firstName}
              backLabel={labels.Access.backButtonLabel}
            />
          </ModuleLayout>
        </UserAcceesFormSteps>
      }
      {nextStep === RECOVER_ACCOUNT_SECOND_STEP &&
        <UserAcceesFormSteps>
          <ModuleLayout
            isDisplayBackButton={false}
          >
            <RecoverAccountSecondStep
              //@ts-ignore
              userFirstName={userInfo?.firstName}
              sendMagicLinkForLogin={sendMagicLinkForLogin}
              submitUserLoginDetail={submitUserLoginDetail}
              recoverAccountMoreInfoLabel={recoverAccountMoreInfoLabel}
              onClickCloseBtn={() => {
                if (otherData.isModal) {
                  typeof otherData.onCloseLoginModal === 'function' && otherData.onCloseLoginModal()
                  return
                }
                router.push('/')
              }}
              backLabel={labels.Access.backButtonLabel}
            />
          </ModuleLayout>
        </UserAcceesFormSteps>
      }
      {nextStep === LOGIN_WITH_VIP_ACCESS_CODE &&
        //@ts-ignore
        <UserAcceesFormSteps onSubmit={(values) => handleVIPAccessCode(values)}>
          <div>
            <ModuleLayout
              backLabel={labels.Access.backButtonLabel}
              isDisplayBackButton
              //@ts-ignore
              onClickBackBtn={() => { router.push('/') }}
            >
              <EnterVIPAccessCodeStep redeemKeyErrorMessage={redeemKeyErrorMessage} />
            </ModuleLayout>
            {redeemKeyErrorMessage &&
              <RedeemInvitationInfo
                titleLabel={labels.Access.redeemInvitationKeyInfoTextLabel}
                subtitleLabel={labels.Access.redeemKeyCTALabel}
                redeemInvitationStep={redeemInvitationStep}
                //@ts-ignore
                isModal={otherData.isModal}
              />
            }
          </div>
        </UserAcceesFormSteps>
      }
      {nextStep === REGISTER_NEW_USER_FIRST_STEP &&
        <UserAcceesFormSteps
          //@ts-ignore
          onSubmit={(values) => handleUserRegistration(values)}
          validationSchema={() => Yup.object().shape({
            firstName: Yup.string()
              .required(labels.Access.firstNameRequiredLabel)
              .matches(validationConstants.NON_ROMAN, labels.Access.romamCharactersErrorLabel),
            lastName: Yup.string()
              .required(labels.Access.lastNameRequiredLabel)
              .matches(validationConstants.NON_ROMAN, labels.Access.romamCharactersErrorLabel),
            email: Yup.string()
              .required(labels.Access.emailRequiredLabel)
              .email(labels.Access.invalidEmailErrorLabel)
              .matches(
                validationConstants.ROMAN_ACCENTED, labels.Access.romamCharactersErrorLabel
              ),
            password: Yup.string()
              .min(12, labels.Access.passwordValidationFailedLabel)
              .matches(validationConstants.AT_LEAST_8CHAR_ONE_UPPERCASE_ONE_NUMBER,
                labels.Access.passwordValidationFailedLabel)
              .matches(validationConstants.NON_ROMAN, labels.Access.passwordValidationFailedLabel),
            termsCondition: Yup.string().oneOf(['true'], labels.Access.termscondtionWarningLabel),
            country: Yup.string().required(labels.Access.countryRequiredLabel)
          })}
        >
          <ModuleLayout
            backLabel={labels.Access.backButtonLabel}
            isDisplayBackButton
            //@ts-ignore
            onClickBackBtn={() => { handleBackArrowClick(null) }}
          >
            <RegisterUserFirstStep
              //@ts-ignore
              countries={countries}
            />
          </ModuleLayout>
        </UserAcceesFormSteps>
      }
      {nextStep === REDEEM_INVITATION_KEY_STEP &&
        //@ts-ignore
        <UserAcceesFormSteps onSubmit={(values) => { verifyUserAlreadyRegistered(values) }}>
          <ModuleLayout
            backLabel={labels.Access.backButtonLabel}
            isDisplayBackButton
            //@ts-ignore
            onClickBackBtn={() => { handleBackArrowClick(LOGIN_WITH_VIP_ACCESS_CODE) }}
          >
            <RedeemInvitationKeyStep />
          </ModuleLayout>
        </UserAcceesFormSteps>
      }
      {nextStep === RESEND_LOGIN_LINK &&
        <UserAcceesFormSteps>
          <ModuleLayout
            backLabel={labels.Access.backButtonLabel}
            isDisplayBackButton
            //@ts-ignore
            onClickBackBtn={() => { handleBackArrowClick(LOGIN_WITH_OR_WITHOUT_PASSWORD) }}
          >
            <ResendLoginLinkStep />
          </ModuleLayout>
        </UserAcceesFormSteps>
      }
      {
        nextStep === RESEND_ACTIVATE_ACCOUNT_LINK_STEP &&
        //@ts-ignore
        <UserAcceesFormSteps onSubmit={(values) => resendLoginLinkRef.current(values, true)}>
          <ModuleLayout
            backLabel={labels.Access.backButtonLabel}
            isDisplayBackButton
            //@ts-ignore
            onClickBackBtn={() => { handleBackArrowClick(null) }}
          >
            <ResendActivateAccountLink activateAccountLabel={activateAccountLabel} />
          </ModuleLayout>
        </UserAcceesFormSteps>
      }
      {nextStep === REGISTER_NEW_USER_SECOND_STEP &&
        <UserAcceesFormSteps>
          <ModuleLayout
            backLabel={labels.Access.backButtonLabel}
            isDisplayBackButton
            //@ts-ignore
            onClickBackBtn={() => { handleBackArrowClick(REGISTER_NEW_USER_FIRST_STEP) }}
          >
            <RegisterUserSecondStep />
          </ModuleLayout>
        </UserAcceesFormSteps>
      }
      {nextStep === CREATE_PASSWORD_STEP &&
        <UserAcceesFormSteps
          //@ts-ignore
          onSubmit={(values) => { createNewPassword(values) }}
          validationSchema={Yup.object().shape({
            newPassword: Yup.string()
              .min(12, labels.Access.passwordValidationFailedLabel)
              .matches(
                validationConstants.AT_LEAST_8CHAR_ONE_UPPERCASE_ONE_NUMBER,
                labels.Access.passwordValidationFailedLabel)
              .matches(
                validationConstants.NON_ROMAN, labels.Access.passwordValidationFailedLabel)
          })}
        >
          <ModuleLayout>
            <CreatePasswordStep
              redirectUserToSpecificUrl={redirectUserToSpecificUrl}
              showLoadingScreen={showLoadingScreen}
            />
          </ModuleLayout>
        </UserAcceesFormSteps>
      }
      {nextStep === RESET_PASSWORD_STEP &&
        <UserAcceesFormSteps
          //@ts-ignore
          onSubmit={(values) => { resetPassword(values) }}
          validationSchema={Yup.object().shape({
            newPassword: Yup.string()
              .required('This Field is required')
              .min(12, labels.Access.passwordValidationFailedLabel)
              .matches(
                validationConstants.AT_LEAST_8CHAR_ONE_UPPERCASE_ONE_NUMBER,
                labels.Access.passwordValidationFailedLabel)
              .matches(
                validationConstants.NON_ROMAN, labels.Access.passwordValidationFailedLabel),
            newPasswordConfirm: Yup.string()
              .required('This Field is required')
              // @ts-ignore
              .oneOf([Yup.ref('newPassword'), null], 'Passwords don\'t match')
          })}
        >
          <ModuleLayout>
            <ResetPasswordStep />
          </ModuleLayout>
        </UserAcceesFormSteps>
      }

      {nextStep === RESET_PASSWORD_LINK_EXPIRED_STEP &&
        <UserAcceesFormSteps
          //@ts-ignore
          onSubmit={(values) => resendResetPasswordLinkRef.current(values, true)}
        >
          <ModuleLayout
            backLabel={labels.Access.backButtonLabel}
            isDisplayBackButton
            onClickBackBtn={() => {
              //@ts-ignore
              handleBackArrowClick(RESET_PASSWORD_STEP)
            }
            }
          >
            <ResetPasswordLinkExpired />
          </ModuleLayout>
        </UserAcceesFormSteps>
      }

      {nextStep === LINK_EXPIRED_STEP &&
        <UserAcceesFormSteps
          //@ts-ignore
          onSubmit={(values) => resendLoginLinkRef.current(values, true)}
        >
          <ModuleLayout
            backLabel={labels.Access.backButtonLabel}
            isDisplayBackButton
            onClickBackBtn={() => {
              Router.push('/', loginAccessPageUrl, { shallow: true })
              //@ts-ignore
              handleBackArrowClick(null)
            }
            }
          >
            <LinkExpiredStep />
          </ModuleLayout>
        </UserAcceesFormSteps>
      }
    </UserAcceesForm>
  )
}

export default UserAccessModule

import React from 'react'
import Router from 'next/router'

import { api } from '../services/_http'
import { clearUserTokens, sessionAccessToken } from '@utils/storage'
import { setLoginData, setAccessToken, setUserAccountTypeFromAPI } from '../stores/user'
import { User as UserService } from '../services'
import { NextPageContext } from 'next'
import { DefaultRootState } from 'react-redux'
import { logger } from '../utils'
import { Store } from 'redux'

const withUserAuth = (App: any) =>
  (class extends React.Component {
    static displayName = 'withUserAuth(User)'

    static async getInitialProps(ctx: {
      // augmentation for the redux object, which is unnatural to Next
      ctx: NextPageContext & { reduxStore: Store<DefaultRootState> }
    }) {
      const {
        ctx: { req, res, query, reduxStore }
      } = ctx

      const q = query || { slug: '/' }
      const accessToken = await sessionAccessToken(req as any)

      let authStatus: { data: ABTypes.Login.SessionSummary | null, ok: boolean } = { data: null, ok: false }

      // Below, Authorization Header will be added for all app level(globalModules etc.) requests.
      // "api" object could be initialized on server-side/globally so, header should be cleaned-up before every request
      api.deleteHeader('Authorization')

      if (accessToken) {
        // Add Authorization header to all "Server Side" requests within _app.js
        api.setHeader('Authorization', `Bearer ${accessToken}`)

        /* Get current user data */
        try {
          // @ts-ignore
          authStatus = await UserService.getLogin()

          if (!('ok' in authStatus && authStatus.ok)) {
            // Current auth token is not valid. Clear Authorization header
            api.deleteHeader('Authorization')
            // Clear invalid/expired tokens from cookies (browser only)
            clearUserTokens(req as any)
          }
        } catch (e) {
          logger('CRITICAL! CANNOT FETCH CURRENT USER DATA')
          logger(e)
        }
      }

      if ('ok' in authStatus && authStatus.ok) {
        // User already has auth token. Dont show Sign-in Page. Redirect to the dashboard
        if (q.slug === '/access' && !q.action) {
          if (res) {
            // @ts-ignore
            res.redirect('/dashboard/overview')
            res.finished = true // Deprecated! Needs to be removed
            res.end()
          } else {
            Router.replace('/dashboard/overview')
          }
          return {}
        }
        // Setting the login token in Redux
        if (authStatus.data) {
          reduxStore.dispatch(setLoginData(authStatus.data as any) as any) // Complex to type, todo
          reduxStore.dispatch(setUserAccountTypeFromAPI() as any) // Complex to type, todo
        }
        accessToken && reduxStore.dispatch(setAccessToken(accessToken) as any) // Complex to type, todo
      }

      return {
        ...(App.getInitialProps ? await App.getInitialProps(ctx) : {})
      }
    }

    render() {
      return <App {...this.props} />
    }
  })

export default withUserAuth

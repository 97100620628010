import qs, { ParsedQuery } from 'query-string'
import http, { api } from './_http'
import { ApiResponse } from 'apisauce'

const User = {
  signInUser: (data: ABTypes.Login.LoginRequest): Promise<ApiResponse<ABTypes.Login.LoginResponse>> => {
    const params = {
      ...data,
      generateCsrfToken: true,
      tcCheck: true,
      expirationPolicy: 'AFTER_LOGIN'
    }
    return api.post('/msvc/v1/login', params)
  },
  signInInfo: (userId: string): Promise<ApiResponse<ABTypes.Login.UserSummary>> => api.get(`/msvc/v1/login/${userId}`),
  generateNewPassword: (data: Paths.DoGenerateNewPasswordUsingPOST.QueryParameters):
    Promise<ApiResponse<Paths.DoGenerateNewPasswordUsingPOST.Responses.$200> & { ok: boolean }> => { // generatenewpassword is part of V1, so, not available in V2 definitions
    // Values coming from the form
    const {
      newPassword,
      newPasswordConfirm,
      crmGuid
    } = data
    // Values to be sent to the API
    const params = {
      newPassword,
      newPasswordConfirm,
      crmGuid,
      generateCsrfToken: true,
      policy: 'PWD_POLICY_V2'
    }
    return api.post(`/msvc/v1/userprofiles/generatenewpassword?${qs.stringify(params)}`)
  },
  resetPassword:(data): Promise<ApiResponse<ABTypes.Login.LoginResponse>> => { // generatenewpassword is part of V1, so, not available in V2 definitions
    // Values coming from the form
    const { newPassword, newPasswordConfirm, token, email } = data
    // Values to be sent to the API
    const params = {
      newPassword,
      newPasswordConfirm,
      token,
      email,
      generateCsrfToken: true
    }
    return api.post(`/msvc/v1/userprofiles/confirmresetpassword?${qs.stringify(params)}`)
  },
  signOut: (): Promise<ApiResponse<void>> => api.delete('/msvc/v1/login'),

  getMe: (isFreshData: boolean = false): Promise<ApiResponse<ABTypes.Userprofiles.User>> => {
    const headers = isFreshData &&
    {
      headers: { 'cache-control': 'no-cache' }
    }
    return api.get('/msvc/v1/userprofiles/me', undefined, headers || {})
  },

  getLogin: (): Promise<ApiResponse<ABTypes.Login.UserSummary>> => api.get('/msvc/v1/login'),

  getProfiles: (): Promise<ApiResponse<Array<any>>> => api.get('/msvc/v1/exhibitorprofile/mine'),

  postTC: (): Promise<ApiResponse<void>> => api.post('/msvc/v1/login/tc'),

  updateUserMain:
    (mainProfileRequest: Paths.DoUpdateMainProfileUsingPUT.Parameters.MainProfileRequest):
      Promise<ApiResponse<Paths.DoUpdateMainProfileUsingPUT.Responses.$200>> =>
      api.put('/msvc/v1/userprofiles/main', mainProfileRequest),

  updateUserAddress: (data: ABTypes.Userprofiles.User): Promise<ApiResponse<ABTypes.Userprofiles.User>> =>
    api.put('/msvc/v1/userprofiles/address', data),

  // TODO: Not 100% sure of these Types
  updateUserPreferences: (data: ABTypes.Userprofiles.OtherInfoRequest):
    Promise<ApiResponse<ABTypes.Userprofiles.User>> =>
    api.put('/msvc/v1/userprofiles/other', data),

  deleteUserAccount: (): Promise<ApiResponse<void>> =>
    api.delete('/msvc/v1/userprofiles/me'),

  userActivateAccount: (data: ParsedQuery):
    Promise<ApiResponse<ABTypes.Login.LoginResponse>> =>
    api.post(`/msvc/v1/userprofiles/activateconfirm?${qs.stringify(data)}`),

  /**
   * Params:
   * data
   * noCache: sends no cache request, which brings data without cache
   */
  userSubscriptions: (data: ParsedQuery<string>, noCache: boolean = false): Promise<ApiResponse<any>> => {
    const headers = noCache &&
    {
      headers: { 'cache-control': 'no-cache' }
    }
    return api.get(`/msvc/v1/userprofiles/subscriptions?${qs.stringify(data)}`, {}, headers || {})
  },

  updateSubscriptions: data => http.put('/msvc/v1/userprofiles/unsubscribe', data),

  getVipRepresentative: (): Promise<ApiResponse<ABTypes.Userprofiles.VipRepresentative>> => api.get('/msvc/v1/userprofiles/vipRepresentative'),

  // @ts-ignore
  getMyAccount: (headers: string): Promise<{ data: Array<number> }> => http.get('/msvc/v1/accounts/mine', null, headers),

  profileFlyoutOptions: '/msvc/v1/pageviews/menus/profileFlyout',

  // Not sure what is this "OK" in the APIs, or where it comes from, but the code seems to need it
  // @ts-ignore
  reFreshToken: (token: string): ApiResponse<ABTypes.Login.LoginResponse> => http.post('/msvc/v1/login/refreshSession', null, {
    'X-REFRESH-AUTHORIZATION': token
  }),

  userBasicInfo: (email: { email: string }): Promise<ApiResponse<ABTypes.Userprofiles.UserBasicInfo>> => api.get('/msvc/v2/userprofiles/user/basic', email),
  sendAccessCodeToEmailId: (params: Paths.RequestEmailCodeUsingPOST.QueryParameters): Promise<ApiResponse<any>> => api.post(`/msvc/v1/login/emailCodes?${qs.stringify(params)}`),
  setPasswordForLoggedInUser: (password) => http.put('/msvc/v2/userprofiles/user/{id}/password', password),
  postTCv2: (params: { termsAndConditionsAccepted: boolean, userId?: string }): Promise<ApiResponse<ABTypes.Login.SessionSummary>> => api.post('/msvc/v1/login/tc', params),
  userAccessRegister: (data): Promise<ApiResponse<ABTypes.Userprofiles.UserRegistrationRequest>> => {
    const {
      email, firstName, lastName, country, newsletter, termsCondition, redirect, password
    } = data
    const reqParams: ABTypes.Userprofiles.UserRegistrationRequest = {
      'email': email,
      'policy': 'PWD_POLICY_V2',
      'properties': [
        { 'name': 'firstName', 'value': firstName },
        { 'name': 'lastName', 'value': lastName },
        { 'name': 'cnCountryId', 'value': country },
        { 'name': 'newsletterSubscribed', 'value': newsletter },
        { 'name': 'termConditionAccepted', 'value': termsCondition },
        { 'name': 'password', 'value': password }
      ]
    }
    if (redirect) { reqParams.redirect = redirect }
    return api.post('/msvc/v2/userprofiles/user/', reqParams)
  },
  sendResetPasswordLink: (email: string): Promise<ApiResponse<ABTypes.Login.SessionSummary>> => api.post(`/msvc/v1/userprofiles/resetpassword?email=${email}`),
  validateResetPasswordLink: (params: { email: string, token: string }): Promise<ApiResponse<ABTypes.Login.SessionSummary>> => api.post(`/msvc/v1/userprofiles/resetpassword/token?${qs.stringify(params)}`),
  postRecoveryAccountData: (params: Paths.RecoverDeletedUserAccountUsingPOST.QueryParameters): Promise<ApiResponse<any>> => api.post(`/msvc/v2/userprofiles/recover?${qs.stringify(params)}`)
}

export default User

import { FC } from 'react'
import styled from 'styled-components'

import { Icon } from '@mch-group/uikit-components'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

interface RedeemInvitationInfoProps {
  titleLabel: string,
  redeemInvitationStep: Function,
  subtitleLabel: string,
  isModal: boolean
}

const RedeemInvitationInfo: FC<RedeemInvitationInfoProps> = ({
  titleLabel,
  subtitleLabel,
  redeemInvitationStep,
  isModal
}) => (
  <Main
    className={`d-flex justify-content-center flex-nowrap redeem-key-wrapper ${isModal ? 'pt-8' : 'pt-4'}`}
    //@ts-ignore
    isModal={isModal}
  >
    {/** @ts-ignore */}
    <RedeemKeyInfoWrapper className='bg-white' isModal={isModal}>
      <div className={`d-flex align-items-center my-7 flex-wrap ${isModal ? 'me-7 ms-8' : 'mb-8 mb-md-7 ms-6 ms-md-8'}`}>
        <div>
          <Icon name='invitation-key' />
        </div>
        <div className='ms-5 ms-md-6'>
          <p className='text-medium mb-0'>
            {titleLabel}
          </p>
        </div>
        <div
          className='ms-9 ms-md-8 mt-8 mt-md-0'
          onClick={()=>{redeemInvitationStep()}}
          onKeyUp={()=>{redeemInvitationStep()}}
          role='button'
          tabIndex={0}
        >
          <span className='text-link'>{subtitleLabel}</span>
        </div>
      </div>
    </RedeemKeyInfoWrapper>
  </Main>
)

const Main = styled.div`
  @media (max-width: 767px) {
    ${
      //@ts-ignore
      (props) => (props.isModal ? '' : `width: 342px; margin: 0 auto;background: ${tokens.color.base.primary.white.value}; margin-top: -42px;`)
    }
  }
`

const RedeemKeyInfoWrapper = styled.div`
  ${
    //@ts-ignore
    (props) => (props.isModal ? '' : 'width: 442px;')
  }
  border-radius: 2px;
  margin-top: -5px;
  ${
    //@ts-ignore
    (props) => (props.isModal ? 'border: 2px solid var(--bs-mch-gray-200);' : '')
  }
  border-left: 4px solid;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;

  @media (max-width: 767px) {
    ${
      //@ts-ignore
      (props) => (props.isModal ? '' :
    'width: 290px;border: 2px solid var(--bs-mch-gray-200);border-left: 4px solid;border-top-left-radius: 2px;border-bottom-left-radius: 2px;margin-bottom: 32px;')
  }
  }

  p {
    ${
      //@ts-ignore
      (props) => (props.isModal ? 'width: 150px;' : 'width: 215px;')
    }

    @media (max-width: 767px) {
      ${
        //@ts-ignore
        (props) => (props.isModal ? 'width: 180px;' : 'width: 215px;')
      }
    }
  }
`

export default RedeemInvitationInfo